/**
 * @file Revival Kids.
 */

/**
 * Revival Kids functions.
 *
 * Runs js specific to the Revival Kids template.
 */
const mRevivalKids = () => {
	if (document.querySelector('.m-kids-sponsor')) import('../../components/kids-sponsor/kids-sponsor').then(({ default: mKidsSponsor }) => { mKidsSponsor(); });
};

if (document.readyState !== 'loading') {
	mRevivalKids();
} else {
	document.addEventListener('DOMContentLoaded', mRevivalKids);
}
